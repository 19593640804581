#launchContainer {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

#launchContainer div {
  text-align: center;
}

#launchContainer .welcome-message {
  line-height: 0;
}

#launchContainer .logo-holder {
  margin-bottom: 2rem;
}

#launchContainer .button-holder {
  margin-top: 2rem;
}

#launchContainer #launch {
  background-color: #D62805;
  color: #EAEBE4;
  border-radius: 0;
  margin-right: 0;
  letter-spacing: 0.5rem;
}
